import { lazy, memo, Suspense } from "react"
import { Navigate, Route, Routes } from "react-router"
import { AuthenticationGuard } from "@/components/AuthenticationGuard"
import { LoadingScreen } from "@/components/LoadingScreen"
import { LoadingSpinner } from "@/components/LoadingSpinner"
import { Layout } from "@/layouts"
import { AuthRoutes, PageRoutes } from "@/utils/constants"

// Lazy-load pages
const HomePage = lazy(() => import("@/pages/HomePage"))
const OrdersPage = lazy(() => import("@/pages/OrdersPage"))
const OrderDetailsPage = lazy(() => import("@/pages/OrderDetailsPage"))
const CredentialsPage = lazy(() => import("@/pages/CredentialsPage"))
const SignupWithInvitePage = lazy(() => import("@/pages/SignupWithInvitePage"))
const MerchantsPage = lazy(() => import("@/pages/MerchantsPage"))
const MerchantDetailsPage = lazy(() => import("@/pages/MerchantDetailsPage"))

// Route mapping (protected routes)
const routes = [
    { path: PageRoutes.HOME, component: HomePage },
    { path: PageRoutes.ORDERS, component: OrdersPage },
    { path: `${PageRoutes.ORDERS}/:orderCode`, component: OrderDetailsPage },
    { path: PageRoutes.CREDENTIALS, component: CredentialsPage },
    { path: PageRoutes.MERCHANTS, component: MerchantsPage },
    { path: `${PageRoutes.MERCHANTS}/:merchantCode`, component: MerchantDetailsPage },
]

export const defaultRoute = PageRoutes.HOME

export const Router = memo(() => {
    return (
        // Use React.Suspense to lazy-load components, and show a loading message while loading
        <Suspense fallback={<LoadingScreen />}>
            <Routes>
                {/* Public routes */}
                <Route path={AuthRoutes.LOGIN} element={<SignupWithInvitePage />} />
                <Route path={AuthRoutes.CALLBACK} element={<LoadingSpinner />} />
                <Route path={AuthRoutes.LOGOUT} element={<Navigate to={"/"} replace />} />
                <Route path={"/"} element={<AuthenticationGuard component={Layout} />}>
                    {/* Protected routes */}
                    {routes.map(({ path, component: Component }) => (
                        <Route key={path} path={path} element={<Component />} />
                    ))}
                    <Route index element={<Navigate to={defaultRoute} replace />} />
                </Route>
                {/* Catch-all route auth */}
                <Route path="*" element={<Navigate to={defaultRoute} replace />} />
            </Routes>
        </Suspense>
    )
})
