import React, { Suspense } from "react"
import { Outlet } from "react-router"
import { ErrorPage } from "@/components/Error"
import { LoadingScreen } from "@/components/LoadingScreen"
import { Sidebar } from "@/components/Sidebar"
import { UserProvider, useUserContext } from "@/contexts/UserContext"
import { MerchantContextIndicator } from "@/layouts/MerchantContextIndicator"

const Content = () => {
    const { error } = useUserContext()

    if (error) {
        return <ErrorPage error={error} />
    }

    return (
        <main className="flex flex-1 flex-col overflow-y-auto overflow-x-hidden bg-canvas">
            <MerchantContextIndicator />
            <Suspense fallback={<LoadingScreen />}>
                <Outlet />
            </Suspense>
        </main>
    )
}

export const Layout = () => {
    return (
        <UserProvider>
            <div className="flex h-screen w-full">
                <Sidebar />
                <Content />
            </div>
        </UserProvider>
    )
}
