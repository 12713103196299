import React from "react"
import { useNavigate } from "react-router"
import { AppState, Auth0Provider } from "@auth0/auth0-react"
import { config } from "@/config"
import { defaultRoute } from "@/router"

interface Auth0ProviderWithNavigateProps {
    children: React.ReactNode
}

export const Auth0ProviderWithNavigate = ({ children }: Auth0ProviderWithNavigateProps) => {
    const navigate = useNavigate()

    const domain = config.auth.AUTH0_DOMAIN
    const clientId = config.auth.AUTH0_CLIENT_ID
    const redirectUri = config.auth.AUTH0_REDIRECT_URL

    const onRedirectCallback = (appState?: AppState) => {
        console.info("Redirecting from callback. State:", appState)
        navigate(appState?.returnTo || defaultRoute)
    }

    if (!(domain && clientId && redirectUri)) {
        return null
    }

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            authorizationParams={{ redirect_uri: redirectUri }}
            onRedirectCallback={onRedirectCallback}
        >
            {children}
        </Auth0Provider>
    )
}
