import { ErrorPage403, ErrorPage404, ErrorPage500 } from "./ErrorPages"
import { isApiError } from "@/services/apiClient"

interface ErrorPageProps {
    error: Error
}

// Map error codes to specific error page components
export const ErrorPage = ({ error }: ErrorPageProps) => {
    const errorCode = isApiError(error) ? error.response?.status : undefined
    switch (errorCode) {
        case 403:
            return <ErrorPage403 />
        case 404:
            return <ErrorPage404 />
        case 500:
            return <ErrorPage500 />
        default:
            // default to 500 if the code is not specified or unknown
            return <ErrorPage500 />
    }
}
