import { useEffect, useState } from "react"
import { useNavigate } from "react-router"
import { useAuth0 } from "@auth0/auth0-react"
import { decodeJwt } from "jose"
import { AuthRoutes } from "@/utils/constants"

export type MFIPermission =
    | "read:sales_orders"
    | "switch_merchants"
    | "read:merchants"
    | "write:merchants"
    | "read:client_credentials"
    | "write:client_credentials"
    | "read:users"
    | "write:users"

export function useHasPermission(): {
    hasPermission: (permission: MFIPermission) => boolean
    isLoading: boolean
} {
    const { getAccessTokenSilently } = useAuth0()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [permissions, setPermissions] = useState<MFIPermission[]>([])
    useEffect(() => {
        getAccessTokenSilently()
            .then((token) => {
                const payload = decodeJwt<{ permissions: MFIPermission[] }>(token)
                setPermissions(payload["permissions"])
            })
            .catch((error) => {
                console.error("Failed to get access token", error)
                navigate(AuthRoutes.LOGIN)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [])
    const hasPermission = (permission: MFIPermission) => {
        return permissions.includes(permission)
    }
    return { isLoading, hasPermission }
}
