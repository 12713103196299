import { createContext, ReactNode, useContext, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router"
import { useAuth0, User } from "@auth0/auth0-react"
import { setUser } from "@sentry/react"
import { useQuery } from "@tanstack/react-query"
import { LoadingScreen } from "@/components/LoadingScreen"
import { MFIPermission, useHasPermission } from "@/hooks/useHasPermission"
import { defaultRoute } from "@/router"
import { useAuthenticatedApi } from "@/services/apiClient"
import { Merchant } from "@/services/types"
import { PageRoutes } from "@/utils/constants"

interface UserContextType {
    merchant?: Merchant
    merchants: Merchant[]
    isLoading: boolean
    error: Error | null
    user?: User
    hasPermission: (permission: MFIPermission) => boolean
    setMerchant: (merchantCode: string | undefined) => void
}

const localStorageKey = "merchantCode"

const UserContext = createContext<UserContextType | undefined>(undefined)

export const UserProvider = ({ children }: { children: ReactNode }) => {
    const { getMerchants } = useAuthenticatedApi()
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const { user } = useAuth0()
    const { hasPermission, isLoading: permissionsLoading } = useHasPermission()
    // Make sure to fetch all available merchants (may need improvement later with search)
    const allMerchantsFilter = { page_size: 1000 }
    const {
        data,
        isPending: merchantsLoading,
        error,
    } = useQuery({
        queryKey: ["merchants", allMerchantsFilter],
        queryFn: getMerchants(allMerchantsFilter),
        enabled: !permissionsLoading,
    })
    const isLoading = merchantsLoading || permissionsLoading
    const merchants = data?.data ?? []
    const [merchant, setMerchantState] = useState<Merchant | undefined>()

    const setMerchant: UserContextType["setMerchant"] = (merchantCode) => {
        const merchant = merchants?.find((m) => m.merchant_code === merchantCode)
        setMerchantState(merchant)
        if (merchantCode) {
            localStorage.setItem(localStorageKey, merchantCode)
            if (pathname.startsWith(PageRoutes.MERCHANTS)) {
                navigate(defaultRoute)
            }
        } else {
            localStorage.removeItem(localStorageKey)
        }
    }

    useEffect(() => {
        if (!isLoading && merchants) {
            if (hasPermission("switch_merchants")) {
                const storedMerchantCode = localStorage.getItem(localStorageKey)
                // If no stored merchant code or the stored merchant code is not valid
                if (!storedMerchantCode || !merchants.find((m) => m.merchant_code === storedMerchantCode)) {
                    setMerchant(undefined)
                    if (!pathname.startsWith(PageRoutes.MERCHANTS)) {
                        // FIXME: Small hack for react strict mode, because this navigation is
                        //  interfering with the redirection in the router for the "/" route.
                        setTimeout(() => {
                            navigate(PageRoutes.MERCHANTS)
                        }, 0)
                    }
                } else {
                    setMerchant(storedMerchantCode)
                }
            } else {
                setMerchant(merchants[0]?.merchant_code)
            }
        }
    }, [merchants, isLoading])

    useEffect(() => {
        if (user) {
            setUser({
                id: user.sub,
                email: user.email,
            })
        } else {
            setUser(null)
        }
    }, [user])

    return (
        <UserContext.Provider
            value={{
                merchants: merchants || [],
                merchant,
                isLoading: isLoading,
                error,
                user,
                hasPermission,
                setMerchant,
            }}
        >
            {isLoading ? <LoadingScreen /> : children}
        </UserContext.Provider>
    )
}

export const useUserContext = () => {
    const context = useContext(UserContext)
    if (context === undefined) {
        throw new Error("useUserContext must be used within a UserProvider")
    }
    return context
}
