import { FC } from "react"
import { CrossOctagonFilledIcon } from "@partner-tech/pax-icons"
import { Snackbar, SnackbarProps } from "@partner-tech/pax-ui-kit"

type Props = {
    open: boolean
    message: string
    onClose: () => void
    autoHideDuration: SnackbarProps["autoHideDuration"]
}
export const ToastMessage: FC<Props> = ({ message, open, onClose, autoHideDuration }) => {
    return (
        <Snackbar
            open={open}
            autoHideDuration={autoHideDuration}
            onClose={onClose}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            ContentProps={{
                className: "!bg-semantic-background-critical !text-semantic-text-primary !border-0 shadow-md flex-nowrap",
            }}
            message={
                <div className={"flex flex-row items-center gap-4"}>
                    <CrossOctagonFilledIcon size={24} className={"text-semantic-ui-critical"} />
                    {message}
                </div>
            }
        />
    )
}
