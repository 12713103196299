import { StrictMode, useEffect } from "react"
import { BrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router"
import { createRoot } from "react-dom/client"
import * as Sentry from "@sentry/react"
import { QueryClientProvider } from "@tanstack/react-query"
import { App } from "@/App"
import { config } from "@/config"
import { Auth0ProviderWithNavigate } from "@/contexts/Auth0ProviderWithNavigate"
import { createQueryClient } from "@/services/queryClient"
import "@/styles/globals.css"
import "@/utils/suppressConsoleLogs"

if (config.sentry.ENVIRONMENT != "development") {
    Sentry.init({
        dsn: config.sentry.DSN,
        environment: config.sentry.ENVIRONMENT,
        integrations: [
            Sentry.reactRouterV7BrowserTracingIntegration({
                useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
            }),
            Sentry.replayIntegration(),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0,
        // Session Replay
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    })
}

const root = createRoot(document.getElementById("root") as Element)
const queryClient = createQueryClient()

root.render(
    <StrictMode>
        <BrowserRouter>
            <QueryClientProvider client={queryClient}>
                <Auth0ProviderWithNavigate>
                    <App />
                </Auth0ProviderWithNavigate>
            </QueryClientProvider>
        </BrowserRouter>
    </StrictMode>,
)
