import { AvatarDIcon, HomeIcon, ShoppingBagEmptyIcon } from "@partner-tech/pax-icons"
import { NavigationItem, NavItem } from "./NavigationItem"
import { useUserContext } from "@/contexts/UserContext"
import { PageRoutes } from "@/utils/constants"

const iconSize = 24

const navMenuItemsMerchant: NavItem[] = [
    {
        label: "Home",
        route: PageRoutes.HOME,
        icon: <HomeIcon size={iconSize} />,
    },
    {
        label: "Orders",
        route: PageRoutes.ORDERS,
        icon: <ShoppingBagEmptyIcon size={iconSize} />,
    },
]
const navMenuItemsAdmin: NavItem[] = [
    {
        label: "Merchants",
        route: PageRoutes.MERCHANTS,
        icon: <AvatarDIcon size={iconSize} />,
    },
]

export const Navigation = () => {
    const { merchant, isLoading, error } = useUserContext()
    const navItems = merchant ? navMenuItemsMerchant : navMenuItemsAdmin

    return (
        <div className={`mt-16 flex h-full w-full flex-col`}>
            <div className={`flex flex-col items-center gap-8`}>
                {!isLoading && !error && navItems.map((navItem, index) => <NavigationItem key={index} navItem={navItem} />)}
            </div>
        </div>
    )
}
