import { Children, FC, PropsWithChildren } from "react"
import { Loader as PaxLoader } from "@partner-tech/pax-ui-kit"
import cn from "classnames"

interface CenteredLoaderProps extends PropsWithChildren {
    label?: string
    loading?: boolean
    className?: string
}

export const LoadingSpinner: FC<CenteredLoaderProps> = ({ label, className, children, loading = true }) => {
    const hasChildren = Children.count(children) > 0

    return (
        <div className={cn("flex justify-center", { "h-full": !hasChildren })}>
            <PaxLoader loading={loading} label={label} variant="indeterminate" className={className}>
                {children}
            </PaxLoader>
        </div>
    )
}
