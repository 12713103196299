import { FullScreenError } from "@/components/Error/FullScreenError"
import { Error400 as Error400Icon } from "@/components/Icons/Error400"
import { Error500 as Error500Icon } from "@/components/Icons/Error500"

export const ErrorPage403 = () => (
    <FullScreenError
        icon={Error400Icon}
        title="You don't have permission to access this resource."
        supportText="If you believe this is an error, please contact support."
    />
)

export const ErrorPage404 = () => (
    <FullScreenError
        icon={Error400Icon}
        title="The requested item could not be found."
        message="Please check the URL or try again."
        showHomeButton={true}
    />
)

export const ErrorPage500 = () => (
    <FullScreenError
        icon={Error500Icon}
        title="Something went wrong."
        message="Please refresh the page or try again in a few minutes."
        supportText="If the problem persists, please contact support."
    />
)
