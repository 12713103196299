import { ComponentType } from "react"
import { Link } from "react-router"
import { Button } from "@tb/desy"
import { usePlatform } from "@/contexts/PlatformContext"
import { defaultRoute } from "@/router"
import { ErrorIconProps } from "@/utils/types"

interface ErrorPageProps {
    icon: ComponentType<ErrorIconProps>
    title: string
    message?: string
    showHomeButton?: boolean
    supportText?: string
}

export const FullScreenError = ({ icon: Icon, title, message, showHomeButton, supportText }: ErrorPageProps) => {
    const { isMfi } = usePlatform()
    const supportEmail = isMfi ? "support@tradebyte.com" : "zeos-integration@zalando.de"

    return (
        <div className="flex size-full items-center justify-center p-6">
            <div className="flex flex-col items-center gap-12 bg-background-default px-14 py-24 lg:flex-row lg:items-start">
                <Icon className="size-40 md:size-56 lg:size-96" />
                <div className="flex flex-col items-center self-center lg:items-start">
                    <h1 className="mb-4 text-center text-2xl font-bold lg:text-left">{title}</h1>
                    {message && <p className="text-semantic-text-secondary mb-10 text-center lg:text-left">{message}</p>}
                    {showHomeButton && (
                        <Button variant="contained" className="mb-4" component={Link} to={defaultRoute}>
                            Back to Home
                        </Button>
                    )}
                    {supportText && (
                        <span className={"text-center lg:text-left"}>
                            <p className="text-semantic-text-secondary">{supportText}</p>
                            <a
                                href={`mailto:${supportEmail}`}
                                className="text-semantic-interactive-text-default underline hover:text-semantic-interactive-text-hover"
                            >
                                {supportEmail}
                            </a>
                        </span>
                    )}
                </div>
            </div>
        </div>
    )
}
