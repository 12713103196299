import { createContext, FC, PropsWithChildren, useCallback, useContext, useState } from "react"
import { SnackbarProps } from "@partner-tech/pax-ui-kit"
import { ToastMessage } from "@/components/ToastMessage/ToastMessage"

export interface ToastMessageContextType {
    openToastMessage: (message: string, autoHideDuration?: SnackbarProps["autoHideDuration"]) => void
    closeToastMessage: () => void
    isOpen: boolean
    message: string
}

const ToastMessageContext = createContext<ToastMessageContextType | undefined>(undefined)

export const ToastMessageProvider: FC<PropsWithChildren> = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [message, setMessage] = useState("")
    const [autoHideDuration, setAutoHideDuration] = useState<SnackbarProps["autoHideDuration"]>(null)

    const openToastMessage = useCallback((message: string, autoHideDuration: SnackbarProps["autoHideDuration"] = 8000) => {
        setMessage(message)
        setAutoHideDuration(autoHideDuration)
        setIsOpen(true)
    }, [])

    const closeToastMessage = useCallback(() => {
        setIsOpen(false)
    }, [])

    const value = {
        isOpen,
        message,
        openToastMessage,
        closeToastMessage,
    }

    return (
        <ToastMessageContext.Provider value={value}>
            {children}
            <ToastMessage autoHideDuration={autoHideDuration} message={message} open={isOpen} onClose={closeToastMessage} />
        </ToastMessageContext.Provider>
    )
}

export const useToastMessage = () => {
    const context = useContext(ToastMessageContext)
    if (!context) {
        throw new Error("useToastMessage must be used within a ToastMessageProvider")
    }
    return context
}
