import { QueryClient } from "@tanstack/react-query"
import { isApiError } from "@/services/apiClient"

const DEFAULT_RETRIES = 3

const isRetryableErrorCode = (status: number) => {
    return status >= 500 || status === 408 || status === 429
}

type CustomErrorHandler = (error: Error) => boolean | undefined

// Creates a retry handler with support for custom error handling
export const createRetryHandler = (customHandler?: CustomErrorHandler) => {
    return (failureCount: number, error: Error): boolean => {
        // Don't retry if we've hit the maximum retries
        if (failureCount >= DEFAULT_RETRIES) {
            return false
        }

        // Run custom error handler if provided
        // undefined return means continue with default handling
        if (customHandler) {
            const result = customHandler(error)
            if (typeof result === "boolean") {
                return result
            }
        }

        // Default retry behavior
        if (isApiError(error)) {
            // Only retry if we have a valid status code
            return error.response?.status ? isRetryableErrorCode(error.response.status) : false
        }

        // Retry non-API errors (e.g., network errors)
        return true
    }
}

export const createQueryClient = () => {
    return new QueryClient({
        defaultOptions: {
            queries: {
                retry: createRetryHandler(),
            },
        },
    })
}
