import * as Sentry from "@sentry/react"
import { DesyThemeProvider } from "@tb/desy"
import { ErrorPage } from "@/components/Error"
import { ToastMessageProvider } from "@/components/ToastMessage/ToastContext"
import { PlatformProvider } from "@/contexts/PlatformContext"
import { Router } from "@/router"

export const App = () => {
    return (
        <Sentry.ErrorBoundary fallback={<ErrorPage error={new Error()} />}>
            <PlatformProvider>
                <DesyThemeProvider>
                    <ToastMessageProvider>
                        <Router />
                    </ToastMessageProvider>
                </DesyThemeProvider>
            </PlatformProvider>
        </Sentry.ErrorBoundary>
    )
}
